import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ProdGenApi } from './../apiService/prodgen.api';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../apiService/loginservice.service';
import { Tenant, Theme } from './../apiService/classFiles/class.organizations';
import { TranslationService } from './../services/TranslationService';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Language, UserToken, Policy, PolicyAcceptBody, APIV2AccessKey, FullAuthToken  } from './../apiService/classFiles/class.authorization';
import cssVars from 'css-vars-ponyfill';
import { Themes, currenttheme, defpreviewtheme, AppComponent } from '../app.component';
import { SettingType_T, User } from './../apiService/classFiles/class.users';
import { Apiv2Service } from './../apiService/apiv2.service';
import { KnowledgeSmartConfiguration } from './../apiService/classFiles/v2-organizations';
import { SessionTimeoutService } from '../services/session-timeout.service';
//import { ContentUrlService } from '../services/content-url.service';

declare var $: any;

@Component({
  selector: 'app-authentication-reponse-page',
  templateUrl: './authentication-reponse-page.component.html',
  styleUrls: ['./authentication-reponse-page.component.css']
})
export class AuthenticationReponsePageComponent implements OnInit {
  @Input('userEmail') userEmail: string;
  @Output('initiateLoginRoute') initiateLoginRoute = new EventEmitter<any>();

  v_UserAccessKey: string = "";
  v_APIV2AccessKey: APIV2AccessKey = new APIV2AccessKey();
  v_AuthenticationType: string = "";
	hasUserAccessKey: boolean = false;

	policyTitle: string = "";
	policyText: string = "";
	policyAccept: boolean = false;
	PolicyAcceptButtonText: string = "Accept";
	policyList: Array<Policy> = new Array<Policy>();
	policyCurrentNum: number = 1;
	policyTotalNum: number = 1;
	policyChecked: boolean = false;

	keyEmail: string = "";
	keyTenant: string = "";

	messageBoxTitle: string = "";
	messageBoxBody: string = "";
	messageBoxActionText: string = "";
	messageBoxNegativeText: string = "";
	messageBoxCancelText: string = "";
    previewtheme = defpreviewtheme;
    selectedTenantId: string = "";

    KSConfigInfo: KnowledgeSmartConfiguration = new KnowledgeSmartConfiguration();

    constructor(private pinnacleService: ProdGenApi, private APIv2: Apiv2Service, private route: ActivatedRoute, private router: Router, private sharedService: SharedService, private tranService: TranslationService, private _sanitizer: DomSanitizer, private timeoutService: SessionTimeoutService) {
    route.queryParams.subscribe(queryParams => {

      if (queryParams['authenticationType'] != null) {
          this.v_AuthenticationType = queryParams['authenticationType'];
      }

      if (queryParams['securityKey'] != null) {

          this.v_UserAccessKey = queryParams['securityKey'];


          this.v_UserAccessKey = this.v_UserAccessKey.replace(/ /g, "+");



        if (this.v_UserAccessKey != "" && this.v_UserAccessKey != null) {
            localStorage.setItem("isAuthenicated", this.v_AuthenticationType);
            ProdGenApi.setUserAccessKey(this.v_UserAccessKey);

            var v_LanguageSetting = localStorage.getItem("ssoLanguageSetting");

            if (v_LanguageSetting == null || v_LanguageSetting.trim() == "") {
                v_LanguageSetting = "en";
            }

            this.pinnacleService.GetSSOFullLoginData("3be1d57c-22b2-4b19-a08f-1cea291959b6", v_LanguageSetting).subscribe(r => {

                if (r.isTest == true) {
                    if (window.opener != null) {

                        let v_Message = `SSO_Test : True`;
                        window.opener.postMessage(v_Message, "*");
                    }
                }

                if (window.opener != null) {
                    let v_Message = `SSO_Test : True`;
                    window.opener.postMessage(v_Message, "*");
                }

                ProdGenApi.setCurrentTenantId(r.token.tenantid as string);
                localStorage.setItem("lastUsedTenant", r.token.tenantid as string);

                this.v_UserAccessKey = r.token.userAccessKey;
                this.v_APIV2AccessKey = r.token.apiV2AccessKey;

                
                

                localStorage.setItem("selectedLanguage", r.languageSetting);
                tranService.loadTranslationDataFromText(r.languageFile);


                //ProdGenApi.setCurrentTenantId(this.selectedTenantId);
               // ProdGenApi.setSessionTenant(this.tenants.find(x => x.tenantId == this.selectedTenantId));
                /*
                localStorage.setItem("DefaultTenant", r.token.tenantid);
                localStorage.setItem("currentTenant", r.token.tenantid);
                localStorage.setItem("userEmail", r.token.email);

                console.log("Set Email:");
                console.log(r.token.email);
                console.log("Set Tenant:");
                console.log(r.token.tenantid);
                */
                ProdGenApi.setAPIKey(r.apiToken);
                ProdGenApi.setAPIV2BearerToken(this.v_APIV2AccessKey);
                this.keyEmail = r.token.email;


                
                this.selectedTenantId = r.token.tenantid as string;
                ProdGenApi.setSessionOrganization(r.orgData);



                //tranService.loadTranslationDataFromText(r.languageFile);
                this.SetTheme();


                if (r.policyList.length > 0) {
                    this.policyList = r.policyList;
                    this.policyTotalNum = r.policyList.length;
                    this.loadPolicy();
                    //BEFORE
                    if (r.policyList.length > 0) {
                        //Check KS Integration
                        this.sharedService.setV2BearerToken(this.v_APIV2AccessKey);


                        this.APIv2.getKSIntegrationConfigInfo().subscribe(ksci => {
                            this.KSConfigInfo = ksci.knowledgeSmartConfiguration;

                            //Check KS Integration
                            if (this.KSConfigInfo.useKnowledgeSmartIntegration == true) {
                                //Check if accepted in KS
                                this.APIv2.getTermsAndConditionsAcceptedKS(r.token.email).subscribe(ksres => {
                                    if (ksres.acceptedTermsAndConditions == false) {
                                        //Have user accept terms
                                        this.policyList = r.policyList;
                                        this.policyTotalNum = r.policyList.length;
                                        this.loadPolicy();
                                        $('#policyBox').modal('show');
                                    }
                                    else {
                                        //user accepteded in KS, Mark it
                                        //Call API
                                        this.pinnacleService.markPolicyAcceptedForUser(r.token.email, this.selectedTenantId, ksres.policyId, ksres.acceptedDate).subscribe(res => {

                                            //Remove the Policy out the List
                                            let removeIndex: number = r.policyList.findIndex(x => x.policyID == ksres.policyId);
                                            this.policyList = new Array<Policy>();
                                            for (let i = 0; i < r.policyList.length; i++) {
                                                if (i != removeIndex) {
                                                    this.policyList.push(r.policyList[i]);
                                                }
                                            }

                                            //show the Policy Box if there's any Policies left.
                                            if (this.policyList.length > 0) {

                                                //forEach()
                                                //this.policyList = this.policyList; //Taken care of above
                                                this.policyTotalNum = this.policyList.length;
                                                this.loadPolicy();
                                                $('#policyBox').modal('show');
                                            }
                                            else {
                                                //let SSO code go
                                                this.continueLogin(r);
                                            }

                                        }, err => {
                                            this.errorAcceptingPolicy();
                                            return;
                                        });
                                    }
                                });

                            }
                            else {
                                this.policyList = r.policyList;
                                this.policyTotalNum = r.policyList.length;
                                this.loadPolicy();
                                $('#policyBox').modal('show');
                            }
                        }, err => {
                            this.KSConfigInfo = new KnowledgeSmartConfiguration();
                            this.KSConfigInfo.useKnowledgeSmartIntegration = false;
                            this.KSConfigInfo.knowledgeSmartAPI_Key = "";
                            this.KSConfigInfo.assessmentSelfEnroll = false;
                            
                            this.loadPolicy();
                            $('#policyBox').modal('show');
                        });
                    }

                    //AFTER
                    return;
                }
                else {
                    this.continueLogin(r);
                }

                

                //console.log(r);
            });

            

            /*

          this.pinnacleService.GetApiToken("3be1d57c-22b2-4b19-a08f-1cea291959b6").subscribe(r => {
			  ProdGenApi.setAPIKey(r as string);
              
              //console.log("Api set");

              this.pinnacleService.GetTenantFromAccessKey(this.v_UserAccessKey).subscribe(tenantRes => {

                  //console.log("Tenant found");

                  ProdGenApi.setCurrentTenantId(tenantRes.tenantId as string);
                  localStorage.setItem("lastUsedTenant", tenantRes.tenantId as string);

                  this.pinnacleService.getAccessKeyUser(this.v_UserAccessKey).subscribe(user => {

                      //console.log("Got access key");

                      ProdGenApi.setUserAccessKey(this.v_UserAccessKey); // for session
                      

                      localStorage.setItem("userEmail", this.keyEmail);
                      localStorage.setItem("currentTenant", tenantRes.tenantId as string);
                      this.selectedTenantId = tenantRes.tenantId as string;
                      this.SetTheme();


                      this.hasUserAccessKey = true;


                      /*
                      this.pinnacleService.getPoliciesForUser(user.email, tenantRes.tenantId as string).subscribe(policiesResult => {

                          //console.log("got policies, if any");

                          this.keyEmail = user.email;
                          this.keyTenant = tenantRes.tenantId as string;

                          //load any language setting from login
                          var v_LanguageSetting = localStorage.getItem("ssoLanguageSetting");

                          //console.log("language setting");
                          //console.log(v_LanguageSetting);

                          if (v_LanguageSetting == null || v_LanguageSetting == "") {
                              //v_LanguageSetting = "en";
                              this.pinnacleService.getCurrentUserSettingWithTempUserKey("LanguagePreference", this.v_UserAccessKey).subscribe(res => {
                                  v_LanguageSetting = res.settingValue.toString();
                              },
                                  err => {
                                      v_LanguageSetting = "en";
                                  });


                          }
                          else {
                              //localStorage.setItem("ssoLanguageSetting", "");
                          }

                          

                          
                         // console.log("Lang setting:");
                          //console.log(v_LanguageSetting);
                          
                          this.pinnacleService.saveCurrentUserSettingTempKeys("LanguagePreference", v_LanguageSetting, SettingType_T.string, this.v_UserAccessKey).subscribe(res => {

                              //console.log("Lang prefernce set");

                              this.pinnacleService.UpdateUserAccessKeyWithTempKey(v_LanguageSetting, this.v_UserAccessKey).subscribe(key => {

                                  this.v_UserAccessKey = key.userAccessKey;
                                  //console.log("Access key updated");

                                  localStorage.setItem("selectedLanguage", v_LanguageSetting);

                                    if (policiesResult.length > 0) {
                                        this.policyList = policiesResult;
                                        this.policyTotalNum = policiesResult.length;
                                        this.loadPolicy();
                                        $('#policyBox').modal('show');
                                    }
                                    else {

                                        ProdGenApi.setUserAccessKey(this.v_UserAccessKey); // for session


                                        localStorage.setItem("userEmail", this.keyEmail);
                                        localStorage.setItem("currentTenant", tenantRes.tenantId as string);
                                        this.selectedTenantId = tenantRes.tenantId as string;
                                        this.SetTheme();
                                        

                                        this.hasUserAccessKey = true;
                                        

                                        
                                    }



                                }, error => {
                                    console.log(error);
                                });


                          }, err => {
                              localStorage.setItem("rememberMe", "false");
                              localStorage.setItem("longLivedToken", "");

                              sessionStorage.setItem("ChatActive", "false");
                              localStorage.setItem("lastUsedTenant", "");
                              localStorage.setItem("isAuthenicated", "");
                              this.router.navigate(['/login']);
                          });
                          

						  
                      }, err => {
                          localStorage.setItem("rememberMe", "false");
                          localStorage.setItem("longLivedToken", "");

                          sessionStorage.setItem("ChatActive", "false");
                          localStorage.setItem("lastUsedTenant", "");
                          localStorage.setItem("isAuthenicated", "");
                          this.router.navigate(['/login']);
					  });
                      
				  });

				  
			  }, err => {
                  localStorage.setItem("rememberMe", "false");
                  localStorage.setItem("longLivedToken", "");

                  sessionStorage.setItem("ChatActive", "false");
                  localStorage.setItem("lastUsedTenant", "");
                  localStorage.setItem("isAuthenicated", "");
                  this.router.navigate(['/login']);
			  });

            
          });
          */
        }

        else {
            //window.location.href = "http://localhost:4200/#/login/?hideLogin=false";

            if (window.opener != null) {
                console.log("False message sent: A");

                let v_Message = `SSO_Test : False`;
                window.opener.postMessage(v_Message, "*");
            }
            this.hasUserAccessKey = false;
            this.router.navigate(['/login']);

        }

      }

      else {
          if (window.opener != null) {
              console.log("False message sent: B");
              let v_Message = `SSO_Test : False`;
              window.opener.postMessage(v_Message, "*");
          }
        this.hasUserAccessKey = false;
        //window.location.href = "http://localhost:4200/#/login/?hideLogin=false";
          this.router.navigate(['/login']);
      }

    });
  }

  ngOnInit() {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    try { this.userEmail = changes['userEmail'].currentValue } catch (err) { };
    localStorage.setItem("userEmail", this.userEmail);
  }


	loadPolicy() {
		
        this.policyChecked = false;
        
	}

	acceptPolicy() {
        if (this.policyChecked) {
           
            this.policyList.forEach(v_Policy => {
                this.pinnacleService.acceptPolicyForUser(this.keyEmail, this.selectedTenantId, v_Policy.policyID).subscribe(r => {

                }, err => {
                    this.errorAcceptingPolicy();
                });

                
            });

            $('#policyBox').modal('hide');
            //this.onSignInClick();
            
            ProdGenApi.setUserAccessKey(this.v_UserAccessKey); // for session
            ProdGenApi.setAPIV2BearerToken(this.v_APIV2AccessKey);
            //this.hasUserAccessKey = true;

            localStorage.setItem("userEmail", this.keyEmail);
            sessionStorage.setItem("currentTenant", this.selectedTenantId);

            

            /*

            this.sharedService.emitChange("");
            let v_URL = localStorage.getItem("LoginURL");
            if (v_URL == null || v_URL == "") {
                this.router.navigate(['/home']);
            }
            else {
                window.location.href = v_URL;

                // CJR - we should not be doing reloads. If this was needed, we need to figure out why and come up with a better solution
                // window.location.reload();

                localStorage.setItem("LoginURL", "");
            }
            
            */
            this.pinnacleService.getCurrentUser().subscribe(res => {


                ProdGenApi.setSessionUser(res);

                //this.hasUserAccessKey = true;
                this.sharedService.emitChange("");
                let v_URL = localStorage.getItem("LoginURL");

                if (v_URL == null || v_URL == "") {
                    this.router.navigate(['/home']);
                }
                else {
                    //window.location.href = v_URL;
                    v_URL = v_URL.replace(window.location.origin + "/#", "");
                    this.router.navigateByUrl(v_URL);

                    // CJR - we should not be doing reloads. If this was needed, we need to figure out why and come up with a better solution
                    // window.location.reload();

                    localStorage.setItem("LoginURL", "");
                }
            });
            
		}
	}

	errorAcceptingPolicy() {
		this.messageBoxTitle = "An error occurred accepting this policy";
		this.messageBoxBody = "The service was not able to register your acceptance of this policy.";
		this.messageBoxActionText = "";
		this.messageBoxNegativeText = "";
		this.messageBoxCancelText = "OK";

		$('#messageBox').modal('show');
	}

    cancelPolicy() {
		$('#policyBox').modal('hide');
        this.v_UserAccessKey = "";
        this.policyLogout();            //user cancels on policy page but SSO has already set userAccessKey in sessionStorage. Need to "fake" log off the user and redirect to login page.
		
	}

	getPolicyDesc(): SafeHtml {
		let v_Text = "";
		if (this.policyList.length > 0) {
			v_Text = this.policyList[this.policyCurrentNum - 1].text;
		}

		return this._sanitizer.bypassSecurityTrustHtml(v_Text as string);
	}

	getAcceptButtonClass(): string {
		if (this.policyChecked) {
			return "btn themed-button";
		}
		else {
			return "btn disabled-button";
		}
    }




    SetTheme() {
        let appliedtheme = new Theme();
        try {
            appliedtheme = JSON.parse(localStorage.getItem("AppliedTheme" + this.selectedTenantId));

            Object.keys(this.previewtheme.properties).forEach(property => {
                if (property.indexOf("width") != -1 || property.indexOf("radius") != -1) {
                    //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue + "px";
                    this.previewtheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                        function (e) {
                            return "--preview-" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue + "px";

                }
                else if (property.indexOf("font-face") != -1) {
                    //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                    this.previewtheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                        function (e) {
                            return "--preview-" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue;

                }
                else if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                    //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                    this.previewtheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                        function (e) {
                            return "--preview-" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue;

                }

                if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                    document.documentElement.style.setProperty(
                        property,
                        this.previewtheme.properties[property]
                    );
                }
            });

            Object.keys(currenttheme.properties).forEach(property => {
                if (property.indexOf("width") != -1 || property.indexOf("radius") != -1) {
                    //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue + "px";
                    currenttheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                        function (e) {
                            return "--" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue + "px";

                }
                else if (property.indexOf("font-face") != -1) {
                    //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                    currenttheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                        function (e) {
                            return "--" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue;

                }
                else if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1 && property.indexOf("label") == -1) {
                    //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                    currenttheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                        function (e) {
                            return "--" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue;

                }

                if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1 && property.indexOf("label") == -1) {
                    document.documentElement.style.setProperty(
                        property,
                        currenttheme.properties[property]
                    );
                }
            });



            localStorage.setItem(this.selectedTenantId + "_DocsImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("document_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_MyDocsImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("my_document_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_VidsImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("video_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_CourseImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("course_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_LPImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("learning_path_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_WFImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("workflow_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_ProcessImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("process_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_QuizImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("quiz_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_defWorkgroupImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("workgroup_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_EnrollmentImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("enrollment_image")].itemValue);

            cssVars({
                rootElement: document,
                variables: {
                    "--preview-font-face": this.previewtheme.properties["--preview-font-face"],
                    "--preview-page-background-color": this.previewtheme.properties["--preview-page-background-color"],
                    "--preview-page-text-color": this.previewtheme.properties["--preview-page-text-color"],
                    "--preview-page-separator-color": this.previewtheme.properties["--preview-page-separator-color"],
                    "--preview-page-separator-width": this.previewtheme.properties["--preview-page-separator-width"],
                    "--preview-navbar-background-color": this.previewtheme.properties["--preview-navbar-background-color"],
                    "--preview-navbar-foreground-color": this.previewtheme.properties["--preview-navbar-foreground-color"],
                    "--preview-hsl-background-color": this.previewtheme.properties["--preview-hsl-background-color"],
                    "--preview-hsl-foreground-color": this.previewtheme.properties["--preview-hsl-foreground-color"],
                    "--preview-hsl-border-color": this.previewtheme.properties["--preview-hsl-border-color"],
                    "--preview-hsl-border-width": this.previewtheme.properties["--preview-hsl-border-width"],
                    "--preview-hsr-background-color": this.previewtheme.properties["--preview-hsr-background-color"],
                    "--preview-hsr-foreground-color": this.previewtheme.properties["--preview-hsr-foreground-color"],
                    "--preview-hsr-border-color": this.previewtheme.properties["--preview-hsr-border-color"],
                    "--preview-hsr-border-width": this.previewtheme.properties["--preview-hsr-border-width"],
                    "--preview-large-card-background-color1": this.previewtheme.properties["--preview-large-card-background-color1"],
                    "--preview-large-card-foreground-color1": this.previewtheme.properties["--preview-large-card-foreground-color1"],
                    "--preview-large-card-background-color2": this.previewtheme.properties["--preview-large-card-background-color2"],
                    "--preview-large-card-foreground-color2": this.previewtheme.properties["--preview-large-card-foreground-color2"],
                    "--preview-large-card-background-color3": this.previewtheme.properties["--preview-large-card-background-color3"],
                    "--preview-large-card-foreground-color3": this.previewtheme.properties["--preview-large-card-foreground-color3"],
                    "--preview-large-card-border-color": this.previewtheme.properties["--preview-large-card-border-color"],
                    "--preview-large-card-border-width": this.previewtheme.properties["--preview-large-card-border-width"],
                    "--preview-large-card-border-radius": this.previewtheme.properties["--preview-large-card-border-radius"],
                    "--preview-small-card-background-color1": this.previewtheme.properties["--preview-small-card-background-color1"],
                    "--preview-small-card-foreground-color1": this.previewtheme.properties["--preview-small-card-foreground-color1"],
                    "--preview-small-card-background-color2": this.previewtheme.properties["--preview-small-card-background-color2"],
                    "--preview-small-card-foreground-color2": this.previewtheme.properties["--preview-small-card-foreground-color2"],
                    "--preview-small-card-background-color3": this.previewtheme.properties["--preview-small-card-background-color3"],
                    "--preview-small-card-foreground-color3": this.previewtheme.properties["--preview-small-card-foreground-color3"],
                    "--preview-small-card-border-color": this.previewtheme.properties["--preview-small-card-border-color"],
                    "--preview-small-card-border-width": this.previewtheme.properties["--preview-small-card-border-width"],
                    "--preview-small-card-border-radius": this.previewtheme.properties["--preview-small-card-border-radius"],
                    "--preview-panel-title-background-color": this.previewtheme.properties["--preview-panel-title-background-color"],
                    "--preview-panel-title-foreground-color": this.previewtheme.properties["--preview-panel-title-foreground-color"],
                    "--preview-panel-body-background-color": this.previewtheme.properties["--preview-panel-body-background-color"],
                    "--preview-panel-body-foreground-color": this.previewtheme.properties["--preview-panel-body-foreground-color"],
                    "--preview-panel-border-color": this.previewtheme.properties["--preview-panel-border-color"],
                    "--preview-panel-border-width": this.previewtheme.properties["--preview-panel-border-width"],
                    "--preview-panel-border-radius": this.previewtheme.properties["--preview-panel-border-radius"],
                    "--preview-button-background": this.previewtheme.properties["--preview-button-background"],
                    "--preview-button-foreground": this.previewtheme.properties["--preview-button-foreground"],

                    "--font-face": currenttheme.properties["--font-face"],
                    "--page-background-color": currenttheme.properties["--page-background-color"],
                    "--page-text-color": currenttheme.properties["--page-text-color"],
                    "--page-separator-color": currenttheme.properties["--page-separator-color"],
                    "--page-separator-width": currenttheme.properties["--page-separator-width"],
                    "--navbar-background-color": currenttheme.properties["--navbar-background-color"],
                    "--navbar-foreground-color": currenttheme.properties["--navbar-foreground-color"],
                    "--hsl-background-color": currenttheme.properties["--hsl-background-color"],
                    "--hsl-foreground-color": currenttheme.properties["--hsl-foreground-color"],
                    "--hsl-border-color": currenttheme.properties["--hsl-border-color"],
                    "--hsl-border-width": currenttheme.properties["--hsl-border-width"],
                    "--hsr-background-color": currenttheme.properties["--hsr-background-color"],
                    "--hsr-foreground-color": currenttheme.properties["--hsr-foreground-color"],
                    "--hsr-border-color": currenttheme.properties["--hsr-border-color"],
                    "--hsr-border-width": currenttheme.properties["--hsr-border-width"],
                    "--large-card-background-color1": currenttheme.properties["--large-card-background-color1"],
                    "--large-card-foreground-color1": currenttheme.properties["--large-card-foreground-color1"],
                    "--large-card-background-color2": currenttheme.properties["--large-card-background-color2"],
                    "--large-card-foreground-color2": currenttheme.properties["--large-card-foreground-color2"],
                    "--large-card-background-color3": currenttheme.properties["--large-card-background-color3"],
                    "--large-card-foreground-color3": currenttheme.properties["--large-card-foreground-color3"],
                    "--large-card-border-color": currenttheme.properties["--large-card-border-color"],
                    "--large-card-border-width": currenttheme.properties["--large-card-border-width"],
                    "--large-card-border-radius": currenttheme.properties["--large-card-border-radius"],
                    "--small-card-background-color1": currenttheme.properties["--small-card-background-color1"],
                    "--small-card-foreground-color1": currenttheme.properties["--small-card-foreground-color1"],
                    "--small-card-background-color2": currenttheme.properties["--small-card-background-color2"],
                    "--small-card-foreground-color2": currenttheme.properties["--small-card-foreground-color2"],
                    "--small-card-background-color3": currenttheme.properties["--small-card-background-color3"],
                    "--small-card-foreground-color3": currenttheme.properties["--small-card-foreground-color3"],
                    "--small-card-border-color": currenttheme.properties["--small-card-border-color"],
                    "--small-card-border-width": currenttheme.properties["--small-card-border-width"],
                    "--small-card-border-radius": currenttheme.properties["--small-card-border-radius"],
                    "--panel-title-background-color": currenttheme.properties["--panel-title-background-color"],
                    "--panel-title-foreground-color": currenttheme.properties["--panel-title-foreground-color"],
                    "--panel-body-background-color": currenttheme.properties["--panel-body-background-color"],
                    "--panel-body-foreground-color": currenttheme.properties["--panel-body-foreground-color"],
                    "--panel-border-color": currenttheme.properties["--panel-border-color"],
                    "--panel-border-width": currenttheme.properties["--panel-border-width"],
                    "--panel-border-radius": currenttheme.properties["--panel-border-radius"],
                    "--button-background": currenttheme.properties["--button-background"],
                    "--button-foreground": currenttheme.properties["--button-foreground"]
                }
            });
        } catch (e) {

        }
    }

    continueLogin(r: FullAuthToken) {
        console.log("Continue Login");

        localStorage.setItem("userEmail", r.token.email);
        sessionStorage.setItem("currentTenant", this.selectedTenantId);
        ProdGenApi.setUserAccessKey(this.v_UserAccessKey);
        ProdGenApi.setAPIV2BearerToken(this.v_APIV2AccessKey);

        // Call to start the timeout timer when the user is fully logged in.
        this.timeoutService.loggedOut = false;
        this.timeoutService.start();

        this.pinnacleService.getCurrentUser().subscribe(res => {
            console.log("Get Current User");
            console.log(res);

            ProdGenApi.setSessionUser(res);

            //this.hasUserAccessKey = true;
            this.sharedService.emitChange("");
            let v_URL = localStorage.getItem("LoginURL");

            if (v_URL == null || v_URL == "") {
                this.router.navigate(['/home']);
            }
            else {
                //window.location.href = v_URL;
                v_URL = v_URL.replace(window.location.origin + "/#", "");
                this.router.navigateByUrl(v_URL);

                // CJR - we should not be doing reloads. If this was needed, we need to figure out why and come up with a better solution
                // window.location.reload();

                localStorage.setItem("LoginURL", "");
            }
            this.APIv2.checkLaunchPeakPortal();
        });

        
    }

    policyLogout() {            //copiedfrom app.component logout

        this.timeoutService.loggedOut = true;
        this.timeoutService.videoPlaying = false;
        this.timeoutService.stop();
        // maintain the API key
        let tmpApiKey = ProdGenApi.getAPIKey();
        let temptAPIKeyV2 = ProdGenApi.getAPIV2AccessKey();
        sessionStorage.clear();
        ProdGenApi.setAPIKey(tmpApiKey);
        ProdGenApi.setAPIV2BearerToken(temptAPIKeyV2);
        let token = ProdGenApi.getAPIV2AccessKey().bearerToken;
        this.pinnacleService.EndSessionToken(token);
        localStorage.setItem("rememberMe", "false");
        localStorage.setItem("longLivedToken", "");
        sessionStorage.setItem("ChatActive", "false");
        localStorage.setItem("lastUsedTenant", "");
        localStorage.setItem("isAuthenicated", "");
        sessionStorage.setItem("workGroupSortBy", "Asset Name");
        sessionStorage.setItem("volume", "7");
        return this.router.navigate(['/login']);
    }
}
